import { useWindowWidth } from '@react-hook/window-size';
import clsx from 'clsx';
import { classNames } from 'react-select/src/utils';

import InfoText from '../InfoText';

type ChildrenProps = {
  children: React.ReactNode;
  className?: string;
  variant?: 'l' | 'xl' | 'boxed' | 'full' | 'boxed' | 'full';
  color?: 'white' | 'transparent' | 'modal';
  isheader?: boolean;
  islast?: boolean;
  isfooter?: boolean;
  ismiddle?: boolean;
  singleRow?: boolean;
  isButton?: boolean;
};

export function Card({ children, color, variant, className }: ChildrenProps) {
  return (
    <section
      className={clsx(className, {
        'h-full rounded-base border border-gray-200 bg-white': color === 'white',
        'h-full rounded-base bg-white': color === 'modal',
        'flex flex-col py-6 px-4': variant === 'boxed',
        'rounded-t-md': variant === 'full',
      })}
    >
      {children}
    </section>
  );
}

export function CardSection({ children, className, variant }: ChildrenProps) {
  return <section className="flex flex-col gap-2">{children}</section>;
}

export function CardContent({
  children,
  variant,
  color,
  islast,
  isfooter,
  ismiddle,
  className,
  singleRow,
  isButton,
}: ChildrenProps) {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 640;

  return (
    <div
      className={clsx(className, {
        'px-4 py-6': variant === 'boxed' && color === 'transparent',
        'rounded-md border border-gray-200 bg-white py-5 px-4':
          variant === 'boxed' &&
          !islast &&
          !ismiddle &&
          !isfooter &&
          !singleRow &&
          color !== 'transparent',
        'p-0': variant === 'full',
        'mt-6 p-0': variant === 'full' && isButton,
        'rounded-md border border-gray-200 bg-white p-4': variant === 'boxed' && singleRow,
        'flex flex-col gap-6 rounded-t-md border-x border-t border-gray-200 bg-white px-4 py-5':
          variant === 'boxed' && islast,
        'flex flex-col gap-6 rounded-t-none rounded-b-md border border-gray-200 bg-white px-4 py-5':
          variant === 'boxed' && isfooter,
        'flex flex-col rounded-none border-x border-gray-200 bg-white p-4':
          variant === 'boxed' && ismiddle,
      })}
    >
      {children}
    </div>
  );
}

type SectionTitleProps = {
  children: React.ReactNode;
  infoText?: string[];
  className?: string;
};

export function SectionTitle({ children, infoText = [], className }: SectionTitleProps) {
  return (
    <header className={className}>
      <h1 className="-mb-1 font-normal text-gray-900 md:px-0">{children}</h1>
      {infoText.map((t, i) => (
        <InfoText variant="default" key={i}>
          {t}
        </InfoText>
      ))}
    </header>
  );
}

export function CardHeader({ children, className, isheader }: ChildrenProps) {
  return (
    <header
      className={clsx(
        'flex min-h-[43px] items-center justify-between bg-system-card px-4',
        className,
        {
          'rounded-t-base py-3 text-base': isheader,
          'rounded-base py-2 text-sm': !isheader,
        },
      )}
    >
      {children}
    </header>
  );
}

export function CardTitle({ children, variant, color, isheader }: ChildrenProps) {
  return (
    <h1
      className={clsx('font-normal capitalize leading-normal', {
        'w-full whitespace-nowrap text-center text-white': variant === 'l',
        'text-xl text-white lg:text-[26px]': variant === 'xl',
        'text-base text-white': variant === 'boxed',
        'text-white': variant !== 'boxed',
        //'text-base': isheader,
      })}
    >
      {children}
    </h1>
  );
}

export function CardSubTitle({ children, variant, isheader }: ChildrenProps) {
  return (
    <div
      className={clsx('text-sm font-normal capitalize leading-normal text-neutral-200', {
        'w-full whitespace-nowrap text-center': variant === 'l',
        'text-xl lg:text-[26px]': variant === 'xl',
      })}
    >
      {children}
    </div>
  );
}

export default Card;
