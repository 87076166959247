import { useWindowWidth } from '@react-hook/window-size';
import { Field, Form, Formik } from 'formik';
import toast from 'react-hot-toast';

import Button from 'lib/components/Button';
import Card, { CardContent } from 'lib/components/Card';
import Modal, { useModalState } from 'lib/components/Modal';
import ModalHeader from 'lib/components/Modal/ModalHeader';
import PlusIcon from 'public/icons/plus-circle.svg';
import useCreateLocation from 'store/dashboard/useCreateLocation';

import LocationBasicInfo from './add-location/LocationAddressInfoModal';
import LocationContactInfo from './add-location/LocationContactInfoModal';

type AddLocationModalProps = {
  storeId: string;
};

function AddLocationModal({ storeId }: AddLocationModalProps) {
  const modalState = useModalState();
  const windowWidth = useWindowWidth();

  const [info, createLocation] = useCreateLocation();

  return (
    <>
      <Button
        onClick={() => {
          modalState.show();
        }}
        size={'md'}
        variant="primary"
        icon={<PlusIcon className="!h-[20px] w-[20px]" />}
      >
        New Location
      </Button>
      <Modal label="Add New Location" modalState={modalState}>
        <Card variant="full">
          <ModalHeader modalState={modalState}>Create New Location</ModalHeader>
          <div className="max-h-full">
            <Card variant="boxed">
              <Formik
                initialValues={{
                  displayHelp: false,
                  name: '',
                  email: '',
                  website: '',
                  phoneNumber: '',
                  country: '',
                  city: '',
                  addressUnit: '',
                  state: '',
                  street: '',
                  zipCode: '',
                  store: storeId,
                }}
                onSubmit={async (values, form) => {
                  const { displayHelp, website, ...input } = values;

                  try {
                    const result = await createLocation(input, {
                      additionalTypenames: ['Location', 'UserNode', 'Store'],
                    });
                    const errors = result.data?.location?.errors;

                    if (errors) {
                      const fieldErrors = {} as { [key: string]: string[] };

                      errors.forEach(e => {
                        fieldErrors[e?.field as string] = e?.messages as string[];
                      });

                      form.setErrors(fieldErrors);

                      return;
                    }

                    toast.success('Location Created');
                    modalState.hide();
                    form.resetForm();
                  } catch (e) {
                    form.setStatus('Something went wrong. Please try again');
                  }
                }}
              >
                {({ values, isSubmitting }) => (
                  <Form className="flex flex-col gap-6">
                    <LocationBasicInfo />

                    <LocationContactInfo />
                    <CardContent variant="boxed" className="flex flex-col">
                      <Button type="submit" loading={isSubmitting} variant="secondary">
                        Create New Location
                      </Button>
                    </CardContent>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </Card>
      </Modal>
    </>
  );
}

export default AddLocationModal;
