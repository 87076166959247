import { ModalState } from '.';
import { CardHeader, CardTitle } from '../Card';
import CloseModalButton from './CloseModalButton';

type ModalheaderProps = {
  children?: React.ReactNode;
  modalState: ModalState;
};

function ModalHeader(props: ModalheaderProps) {
  return (
    <CardHeader isheader className="min-h-14">
      <CardTitle variant="boxed">{props.children}</CardTitle>
      <CloseModalButton modalState={props.modalState} />
    </CardHeader>
  );
}

export default ModalHeader;
