import { useWindowWidth } from '@react-hook/window-size';
import Link from 'next/link';

import Button from 'lib/components/Button';
import { PopoverMenu, PopoverMenuItem } from 'lib/components/Popover';
import CtxMenuIcon from 'public/icons/ctx-menu-icon.svg';
import LinesIcon from 'public/icons/icon-lines.svg';
import PencilIcon from 'public/icons/icon-pencil.svg';
import StackIcon from 'public/icons/icon-stack.svg';
import TrashIcon from 'public/icons/icon-trash.svg';
import { LocationType as Location } from 'store/types';

type Props = {
  location: Location;
};

function LocationCard({ location }: Props) {
  const windowWidth = useWindowWidth();

  return (
    <div
      className="flex min-h-[62px] items-center justify-between rounded-md border border-gray-200 bg-white px-4 py-3"
      key={location.id}
    >
      <h2 className="font-normal">{location.name}</h2>
      <div className="flex items-center space-x-2">
        <div className="space-x-2 md:flex">
          <Button className="hidden" variant="secondary" outline size="xs">
            View
          </Button>
          <Link href={`/location/${location.id}`}>
            <a className="flex">
              <Button variant="gray" size="sm" outline>
                Manage
              </Button>
            </a>
          </Link>
        </div>
        {/* <PopoverMenu
          label="Context menu"
          trigger={
            <div className="flex w-4 justify-end">
              <CtxMenuIcon />
            </div>
          }
          options={{
            placement: 'bottom-end',
          }}
          menu={[
            ...(windowWidth < 768
              ? ([
                  {
                    onClick: () => {},
                    label: <PopoverMenuItem icon={<LinesIcon />}>View</PopoverMenuItem>,
                    type: 'button',
                  },
                  {
                    href: `/location/${location.id}`,
                    label: <PopoverMenuItem icon={<PencilIcon />}>Manage</PopoverMenuItem>,
                    type: 'link',
                  },
                ] as any[])
              : []),

            {
              onClick: () => {},
              label: <PopoverMenuItem icon={<StackIcon />}>Copy</PopoverMenuItem>,
              type: 'button',
            },
            {
              onClick: () => {},
              label: <PopoverMenuItem icon={<TrashIcon />}>Delete</PopoverMenuItem>,
              type: 'button',
            },
          ]}
        />*/}
      </div>
    </div>
  );
}

export default LocationCard;
