import clsx from 'clsx';
import Image from 'next/image';

import { PopoverMenu, PopoverMenuItem } from 'lib/components/Popover';
import { BasePopoverProps } from 'lib/components/Popover/BasePopover';
import PencilIcon from 'public/icons/icon-pencil.svg';
import StackIcon from 'public/icons/icon-stack.svg';
import TrashIcon from 'public/icons/icon-trash.svg';
import { Store, UserNode } from 'store/types';

type UserMenuProps = {
  textColor?: 'white' | 'black';
  store: Store;
  user: UserNode;
  popoverOptions?: BasePopoverProps['options'];
};

function getDisplayName({ firstName, lastName, username }: UserNode) {
  if (!firstName && !lastName) {
    return username;
  }

  return `${firstName} ${lastName}`;
}

function UserMenu(props: UserMenuProps) {
  const { store, popoverOptions = {}, textColor = 'white' } = props;

  return (
    <div className="flex min-w-0 justify-end">
      <PopoverMenu
        label="User menu"
        options={popoverOptions}
        trigger={
          <div
            className={clsx('max-w-[160px] flex-1 text-left lg:text-right', {
              'text-white': textColor === 'white',
              'text-black': textColor === 'black',
            })}
          >
            <p className="truncate text-sm font-normal">{store?.name}</p>
            <p className="truncate text-sm">{getDisplayName(props.user)}</p>
          </div>
        }
        menu={[
          /* {
            href: '/edit-profile',
            label: <PopoverMenuItem icon={<PencilIcon />}>Edit</PopoverMenuItem>,
          }, */
          {
            href: '/signout',
            label: <PopoverMenuItem icon={<StackIcon />}>Sign out</PopoverMenuItem>,
          },
        ]}
      ></PopoverMenu>
    </div>
  );
}

export default UserMenu;
