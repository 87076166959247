import { Field, useFormikContext } from 'formik';

import { CardContent, CardSection, SectionTitle } from 'lib/components/Card';
import InfoText from 'lib/components/InfoText';
import TextField from 'lib/components/forms/TextField';

const infoTexts = {
  name: ['Enter your location name'],
  address: ['Location address used for pickup & Delivery information'],
  email: ['Email used for contact & reply to information for this location'],
  website: ['Website for this specific location'],
  phoneNumber: ['Default phone number for this location'],
};

function LocationBasicInfo() {
  const { values } = useFormikContext<{ displayHelp: boolean }>();

  return (
    <div className="flex flex-col gap-6">
      <CardSection>
        <SectionTitle infoText={['The physical address of this location used system wide.']}>
          Location Name & Address
        </SectionTitle>
        <CardContent variant="boxed" className="space-y-4">
          <Field
            name="name"
            label="Location Name"
            component={TextField}
            infoText={values.displayHelp ? infoTexts.name : []}
          />

          {values.displayHelp &&
            infoTexts.address.map((text, id) => (
              <InfoText variant="info" key={id}>
                {text}
              </InfoText>
            ))}
          <div className="space-y-4 md:flex md:space-y-0 md:space-x-4">
            <Field
              name="street"
              autoComplete="street-address"
              label="Street"
              component={TextField}
            />
            <Field name="addressUnit" label="Address Unit" component={TextField} />
          </div>
          <div className="space-y-4 md:flex md:space-y-0 md:space-x-4">
            <Field name="city" autoComplete="address-level2" label="City" component={TextField} />
            <Field name="state" autoComplete="address-level1" label="State" component={TextField} />
          </div>
          <div className="space-y-4 md:flex md:space-y-0 md:space-x-4">
            <Field
              name="country"
              label="Country"
              autoComplete="country"
              inputMode="numeric"
              component={TextField}
            />
            <Field
              name="zipCode"
              label="Zip Code"
              autoComplete="postal-code"
              inputMode="numeric"
              component={TextField}
            />
          </div>
        </CardContent>
      </CardSection>
    </div>
  );
}

export default LocationBasicInfo;
