import { Field, useFormikContext } from 'formik';

import { CardContent, CardSection, SectionTitle } from 'lib/components/Card';
import InfoText from 'lib/components/InfoText';
import TextField from 'lib/components/forms/TextField';

const infoTexts = {
  name: ['Enter your location name'],
  address: ['Location address used for pickup & Delivery information'],
  email: ['Email used for contact & reply to information for this location'],
  website: ['Website for this specific location'],
  phoneNumber: ['Default phone number for this location'],
};

function LocationBasicInfo() {
  const { values } = useFormikContext<{ displayHelp: boolean }>();

  return (
    <div className="flex flex-col gap-6">
      <CardSection variant="full">
        <SectionTitle
          infoText={[
            'Enter the contact details for this location. Used for all communication and support.',
          ]}
        >
          Location Contact Details
        </SectionTitle>
        <CardContent variant="boxed">
          <div className="space-y-4">
            <Field
              name="email"
              type="email"
              label="Location Email"
              component={TextField}
              infoText={values.displayHelp ? infoTexts.email : []}
            />
            <Field
              name="website"
              type="text"
              label="Location Website"
              component={TextField}
              infoText={values.displayHelp ? infoTexts.website : []}
            />
            <Field
              name="phoneNumber"
              label="Location Phone"
              inputMode="numeric"
              autoComplete="tel"
              infoText={values.displayHelp ? infoTexts.phoneNumber : []}
              component={TextField}
            />
          </div>
        </CardContent>
      </CardSection>
    </div>
  );
}

export default LocationBasicInfo;
